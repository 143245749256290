/* eslint-disable camelcase */
import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'
import { connect, useSelector } from 'react-redux'
import { formValueSelector } from 'redux-form'
import { loadStripe } from '@stripe/stripe-js'
import { isEmpty } from 'lodash'

import { Modal } from '../../component-library/components/modal/modal.component'
import { Button } from '../../component-library/components/button/button.component'
import {
  StyledTab,
  MODAL_HEIGHT,
  TAB_ITEM_WIDTH,
  StyledSubscriptionCallout,
} from './topup-modal.component.styles'
import {
  ConnectedCreditCardSectionReduxForm,
  topupModalFormName,
} from './credit-card-section/credit-card-section.component'
import { BankTransferSection } from './bank-transfer-section/bank-transfer-section.component'
import { BankTransferSectionSG } from './bank-transfer-section/bank-transfer-section-sg.component'
import { BankTransferSectionTW } from './bank-transfer-section/bank-transfer-section-tw.component'
import { BankTransferSectionVN } from './bank-transfer-section/bank-transfer-section-vn.component'
import { ConnectedExternalTopup } from './external-topup-section/external-topup.component'
import { apiService } from '../../billing/api/api.service'
import { setCreditBalance } from '../../billing/duck/actions'
import { getFeatureFlagPrepaidTopupBankTransferOnly } from '../../billing/duck/selectors'
import { getCountry } from '../../service/locale/country.service'
import { getSavedCard, getStripeCards } from '../../payment/duck/selectors'
import { displayAlert } from '../../component-library/components/alerts/alerts.component'
import { getIsEligibleSubscription } from '../../paid-subscription-plan/duck/selectors'
import { brazeClient, BRAZE_EVENT } from 'ggx-service/braze'
import { PayNowSection } from './pay-now-section/pay-now-section.component'

import { getAddCreditCardModalInfo } from 'ggx-global/duck/selectors'
import {
  showAddCreditCardModal,
  hideAddCreditCardModal,
} from 'ggx-global/duck/actions'
import { AddCreditCardModal } from 'ggx-src/billing/components/add-credit-card-modal.component'
import { EditCreditCardModal } from 'ggx-src/billing/components/edit-credit-card-modal.component'

const CREDIT_TAB_ID = 'credit_card-id'
const BANK_TAB_ID = 'bank_transfer-id'
const EXTERNAL_TOPUP_ID = 'external_topup_id'
const PAYNOW_TAB_ID = 'PAYNOW_TAB_ID'

const TopupModal = ({
  handleModalClose,
  dispatch,
  topupPackageId,
  featureFlagPrepaidTopupBankTransferOnly,
  featureFlagEnablePayNowPayment,
  isEligibleSubscription,
}) => {
  const [t] = useTranslation()

  const creditCardTab = {
    label: t('billing.topup.modal.tab.creditcard'),
    id: CREDIT_TAB_ID,
  }

  const bankTransferTab = {
    label: t('billing.topup.modal.tab.banktransfer'),
    id: BANK_TAB_ID,
  }

  const externalTopupTab = {
    label: t('billing.topup.modal.tab.creditcard'),
    id: EXTERNAL_TOPUP_ID,
  }

  const payNowTab = {
    label: t('common__paynow'),
    id: PAYNOW_TAB_ID,
  }

  const country = getCountry()

  const getTabOptions = () => {
    if (country === 'TW') {
      return [externalTopupTab, bankTransferTab]
    }

    return [
      !featureFlagPrepaidTopupBankTransferOnly && creditCardTab,
      country === 'SG' && featureFlagEnablePayNowPayment && payNowTab,
      bankTransferTab,
    ].filter(Boolean)
  }

  const tabOptions = getTabOptions()

  const [tabOption, setTabOption] = useState(tabOptions[0].id)
  const stripeCards = useSelector(state => getStripeCards(state) ?? [])
  const hasCreditCard = stripeCards.length > 0
  const [addCreditInProgress, setAddCreditInProgress] = useState(false)

  const onTabClickHandler = event => {
    setTabOption(event.target.id)
  }

  const onSpaceKeyPressHandler = event => {
    if (event.key === ' ') {
      event.preventDefault()
      setTabOption(event.target.id)
    }
  }

  const [selectedCreditCardId, setSelectedCreditCardId] = useState(undefined)
  const handleSubmit = async () => {
    let isSuccessful = false
    let needsAuthentication = false
    // eslint-disable-next-line one-var, one-var-declaration-per-line
    let stripeAccountId, clientSecret, paymentIntentId

    setAddCreditInProgress(true)

    try {
      await apiService.addCredit(topupPackageId, null, selectedCreditCardId)
      brazeClient.setCustomEvent(BRAZE_EVENT.PAYMENT.WALLET_TOPUP_PAID)
      isSuccessful = true
    } catch (error) {
      if (error.response?.data?.i18n_key === '3ds_auth_required') {
        const {
          stripe_account_id,
          payment_intent: { client_secret, id },
        } = error.response.data.extras

        needsAuthentication = true
        stripeAccountId = stripe_account_id
        clientSecret = client_secret
        paymentIntentId = id
      }
    }

    if (needsAuthentication) {
      try {
        const stripe = await loadStripe(process.env.STRIPE_KEY, {
          stripeAccount: stripeAccountId,
        })
        const result = await stripe.confirmCardPayment(clientSecret)

        if (result.paymentIntent) {
          try {
            await apiService.addCredit(topupPackageId, paymentIntentId)

            isSuccessful = true
          } catch {
            isSuccessful = false
          }
        }
      } catch {
        isSuccessful = false
      }
    }

    if (isSuccessful) {
      setAddCreditInProgress(false)
      displayAlert({ message: t('systemMessage.topup.successful') })

      try {
        const {
          credits_balance: credit,
          free_credits_balance: freeCredit,
        } = await apiService.getPrepaidBalance()
        dispatch(setCreditBalance({ credit, freeCredit }))
      } catch (error) {
        displayAlert({
          message: t('error.topup.retrieveBalance'),
          type: 'error',
        })
      }

      handleModalClose()
    } else {
      setAddCreditInProgress(false)
      displayAlert({
        message: t('systemMessage.topup.unsuccessful'),
        type: 'error',
      })
    }
  }

  const noTopupPackageSelected = topupPackageId === undefined
  const noSavedCreditCard = !hasCreditCard
  const isAddCreditDisabled =
    noTopupPackageSelected ||
    addCreditInProgress ||
    noSavedCreditCard ||
    !selectedCreditCardId
  useEffect(() => {
    if (noSavedCreditCard) setSelectedCreditCardId(undefined)
  }, [noSavedCreditCard])
  const payNowButton = tabOption === CREDIT_TAB_ID && (
    <Button
      buttonType="primary"
      size="small"
      onClick={handleSubmit}
      disabled={isAddCreditDisabled}
      data-cy="pay_now_btn"
    >
      {t('billing.topup.modal.button.paynow')}
    </Button>
  )

  const renderBankTransfer = () => {
    let staticFormSection = null
    switch (country) {
      case 'HK':
        staticFormSection = <BankTransferSection />
        break
      case 'SG':
        staticFormSection = <BankTransferSectionSG />
        break
      case 'TW':
        staticFormSection = <BankTransferSectionTW />
        break
      case 'VN':
        staticFormSection = <BankTransferSectionVN />
        break
      default:
        staticFormSection = null
    }
    return staticFormSection
  }

  const showSubscriptionCallout =
    tabOption === CREDIT_TAB_ID && isEligibleSubscription

  useEffect(() => {
    brazeClient.setCustomEvent(BRAZE_EVENT.PAYMENT.WALLET_TOPUP)
  }, [])

  const handlePayNowSuccess = useCallback(async () => {
    displayAlert({ message: t('systemMessage.topup.successful') })
    const {
      credits_balance: credit,
      free_credits_balance: freeCredit,
    } = await apiService.getPrepaidBalance()
    dispatch(setCreditBalance({ credit, freeCredit }))
    handleModalClose()
  }, [handleModalClose])
  const handlePayNowFailure = useCallback(() => {
    displayAlert({
      message: t('systemMessage.topup.unsuccessful'),
      type: 'error',
    })
  }, [])

  const addCreditCardModalInfo = useSelector(state =>
    getAddCreditCardModalInfo(state)
  )
  const [displayEditCreditCardInfo, setDisplayEditCreditCardInfo] = useState({})

  return (
    <>
      {!(
        addCreditCardModalInfo?.visible || !isEmpty(displayEditCreditCardInfo)
      ) && (
        <Modal
          header={t('billing.topup.modal.header')}
          footer={payNowButton}
          onRequestClose={handleModalClose}
          height={
            tabOptions.length > 1 && tabOption !== PAYNOW_TAB_ID
              ? MODAL_HEIGHT
              : '342px'
          }
        >
          {tabOptions.length > 1 && (
            <StyledTab
              items={tabOptions}
              value={tabOption}
              itemWidth={TAB_ITEM_WIDTH}
              onClick={onTabClickHandler}
              onKeyPress={onSpaceKeyPressHandler}
            />
          )}
          {showSubscriptionCallout && (
            <StyledSubscriptionCallout message={t('text__free_credit__cta')} />
          )}
          {tabOption === CREDIT_TAB_ID && (
            <ConnectedCreditCardSectionReduxForm
              onChangeValue={value => setSelectedCreditCardId(value)}
              onClickAdd={() => dispatch(showAddCreditCardModal())}
              onClickEdit={card => setDisplayEditCreditCardInfo(card)}
            />
          )}
          {tabOption === EXTERNAL_TOPUP_ID && <ConnectedExternalTopup />}
          {tabOption === BANK_TAB_ID && renderBankTransfer()}
          {tabOption === PAYNOW_TAB_ID && (
            <PayNowSection
              onSuccess={handlePayNowSuccess}
              onFailure={handlePayNowFailure}
            />
          )}
        </Modal>
      )}
      {addCreditCardModalInfo?.visible && (
        <AddCreditCardModal
          onModalClose={() => dispatch(hideAddCreditCardModal())}
          cardItent={addCreditCardModalInfo.cardItent}
        />
      )}
      {!isEmpty(displayEditCreditCardInfo) && (
        <EditCreditCardModal
          {...displayEditCreditCardInfo}
          onModalClose={() => setDisplayEditCreditCardInfo({})}
        />
      )}
    </>
  )
}

TopupModal.propTypes = {
  handleModalClose: PropTypes.func,
  dispatch: PropTypes.func,
  topupPackageId: PropTypes.string,
  featureFlagPrepaidTopupBankTransferOnly: PropTypes.bool,
  featureFlagEnablePayNowPayment: PropTypes.bool,
  isEligibleSubscription: PropTypes.bool,
}

const selector = formValueSelector(topupModalFormName)

const mapStateToProps = state => ({
  topupPackageId: selector(state, 'topup_package_id'),
  featureFlagPrepaidTopupBankTransferOnly: getFeatureFlagPrepaidTopupBankTransferOnly(
    state
  ),
  featureFlagEnablePayNowPayment: state.featureFlagEnablePayNowPayment,
  isEligibleSubscription: getIsEligibleSubscription(state),
})

const ConnectedTopupModal = connect(mapStateToProps)(TopupModal)

export { TopupModal, ConnectedTopupModal }
