import styled from "styled-components"

import { Tab } from "../../component-library/components/tab/tab.component"
import { SPACING } from "../../component-library/design/spacing/spacing.constants"
import { SubscriptionCallout } from "../../paid-subscription-plan/subscription-callout/subscription-callout.component"

const MODAL_HEIGHT = "640px"
const TAB_ITEM_WIDTH = "140px"

const StyledTab = styled(Tab)`
  margin-bottom: ${SPACING.M};
`

const StyledSubscriptionCallout = styled(SubscriptionCallout)`
  margin-bottom: -${SPACING.XS};
`

export { StyledTab, MODAL_HEIGHT, TAB_ITEM_WIDTH, StyledSubscriptionCallout }
