import React, { useEffect } from 'react'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'
import {
  Header,
  StyledTitle,
  Footer,
  Content,
  StyledCrossIcon,
  StyledReactModal,
  Wrapper,
} from './modal.component.styles'

ReactModal.setAppElement(document.getElementById('main_container'))

const Modal = ({
  contentLabel,
  header,
  footer,
  onRequestClose = () => {},
  children,
  height,
  maxHeight,
  minHeight,
  width,
  className,
  overlayClass,
  showCloseButton,
  highlights,
  modalPosition,
  customStyles,
}) => {
  const wrapperTestId = header
    ? `${header.toLowerCase().replace(/\W/g, '-')}-modal-wrapper`
    : 'modal-wrapper'
  // https://github.com/styled-components/styled-components/issues/2487
  // not using styled components as createGlobalStyle is not removed on unmount
  useEffect(() => {
    document.querySelector('body').setAttribute('style', 'overflow: hidden;')

    return () => {
      document.querySelector('body').setAttribute('style', 'overflow: visible;')
    }
  }, [])

  const handleSpaceKeyPress = event => {
    if (event.key === ' ') {
      event.preventDefault()
      onRequestClose()
    }
  }

  return (
    <StyledReactModal
      isOpen
      contentLabel={contentLabel}
      shouldCloseOnEsc
      onRequestClose={onRequestClose}
      shouldCloseOnOverlayClick={false}
      shouldReturnFocusAfterClose={false}
      ariaHideApp={false}
      $highlights={highlights}
      style={customStyles}
      overlayClass={overlayClass}
    >
      <Wrapper
        className={className}
        $height={height}
        $maxHeight={maxHeight}
        $minHeight={minHeight}
        $width={width}
        data-testid={wrapperTestId}
        $modalPosition={modalPosition}
      >
        {header && (
          <Header>
            <StyledTitle>{header}</StyledTitle>
          </Header>
        )}
        {showCloseButton && (
          <StyledCrossIcon
            onClick={onRequestClose}
            tabIndex={0}
            onKeyPress={handleSpaceKeyPress}
            data-cy="modal-close-btn"
          />
        )}

        <Content showHeader={!!header}>{children}</Content>

        {footer && <Footer>{footer}</Footer>}
      </Wrapper>
    </StyledReactModal>
  )
}

Modal.propTypes = {
  contentLabel: PropTypes.string,
  onRequestClose: PropTypes.func.isRequired,
  header: PropTypes.string,
  children: PropTypes.node.isRequired,
  footer: PropTypes.node,
  height: PropTypes.string,
  className: PropTypes.string,
  showCloseButton: PropTypes.bool,
  width: PropTypes.string,
  highlights: PropTypes.arrayOf(
    PropTypes.shape({
      x: PropTypes.string,
      y: PropTypes.string,
      width: PropTypes.string,
      height: PropTypes.string,
    })
  ),
  modalPosition: PropTypes.shape({
    left: PropTypes.string,
    top: PropTypes.string,
  }),
  customStyles: PropTypes.object,
}

Modal.defaultProps = {
  contentLabel: 'Popup Window',
  showCloseButton: true,
  highlight: null,
  modalPosition: null,
}

export { Modal }
