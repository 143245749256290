import { useTranslation } from 'react-i18next'

import { formatCurrency } from 'ggx-service/i18n/format-currency.service'
import { formatDateTime } from 'ggx-service/date-time/format-date-time'
import { Body } from 'ggx-componentlibrary/design/typography/typography.styles'
import {
  FlexContainerColumn,
  FlexContainer,
  SubText,
  ActiveStatusIcon,
  CancelledStatusIcon,
  PendingStatusIcon,
  CompletedStatusIcon,
  CustomizedHeader,
  CustomizedCell,
  CustomizedTextCell,
} from './order-table.component.styles'
import { RouteList } from './route-list/route-list.component'
import { VEHICLE_TRANSLATIONS } from 'ggx-service/vehicle/vehicle'
import { useFeatureFlagValue } from 'ggx-global/hooks/use-feature-flag'
import { getCountry } from 'ggx-service/locale/country.service'

const useColumns = ({ isSubUser }) => {
  const [t] = useTranslation()
  const premiumBlackVanFeatureValue = useFeatureFlagValue(
    'premium_black_van_ab_testing'
  )

  const columns = [
    {
      key: 'id',
      headerLabel: t('common__order_id'),
      width: '86px',
      headerComponent: ({ label }) => (
        <CustomizedHeader>{label}</CustomizedHeader>
      ),
      cellComponent: ({ value }) => <CustomizedCell>#{value}</CustomizedCell>,
    },
    {
      key: 'status',
      headerLabel: t('common__status'),
      width: '126px',
      headerComponent: ({ label }) => (
        <CustomizedHeader>{label}</CustomizedHeader>
      ),
      cellComponent: ({ value, rowData }) => {
        const eta = rowData?.eta
        const arrivedAtFirstWaypoint = Boolean(
          rowData?.waypoints?.[0]?.driver_arrived_at
        )

        let statusText
        let statusIcon
        let timeInformation

        switch (value) {
          case 'cancelled':
            statusText = t('common__cancelled')
            statusIcon = <CancelledStatusIcon />
            timeInformation = formatDateTime(rowData.updated_at)
            break
          case 'completed':
            statusText = t('common__completed')
            statusIcon = <CompletedStatusIcon />
            timeInformation = formatDateTime(rowData.resolved_at)
            break
          case 'picked':
          case 'pending':
            statusText = t('common__pending')
            statusIcon = <PendingStatusIcon />
            timeInformation = null
            break
          case 'active':
            statusText = t('common__active')
            statusIcon = <ActiveStatusIcon />

            timeInformation =
              eta && arrivedAtFirstWaypoint
                ? t('text__estimated_drop-off_in', { time: eta })
                : t('text__estimated_pick_up_at', { time: eta })

            break
          default:
        }

        return (
          <FlexContainer style={{ margin: '0 8px' }}>
            {statusIcon}
            <FlexContainerColumn>
              <Body noMargin data-testid="order-table-status-cell">
                {statusText}
              </Body>
            </FlexContainerColumn>
          </FlexContainer>
        )
      },
    },
    {
      key: 'waypoints',
      headerLabel: t('common__route'),
      width: '249px',
      headerComponent: ({ label }) => (
        <CustomizedHeader>{label}</CustomizedHeader>
      ),
      cellComponent: ({ rowData = {} }) => <RouteList rowData={rowData} />,
    },
    {
      key: 'pickup_time',
      width: '100px',
      headerLabel: t('common__ggv__pick_up_time'),
      headerComponent: ({ label }) => (
        <CustomizedHeader>{label}</CustomizedHeader>
      ),
      cellComponent: ({ value }) => (
        <CustomizedCell>
          {formatDateTime(value).replace(',', '\n')}
        </CustomizedCell>
      ),
    },
    {
      width: '120px',
      headerLabel: t('common__sender_info__ggv'),
      headerComponent: ({ label }) => (
        <CustomizedHeader>{label}</CustomizedHeader>
      ),
      cellComponent: ({ rowData }) => {
        /**
         * We lack sender information in our database.
         * hence, we utilize the first waypoint as the sender's details.
         * However, if an order is placed through CA, providing the waypoint contact name and phone number isn't mandatory.
         * In such cases, we resort to using the nickname and phone number as alternative information.
         * https://gogotech.atlassian.net/browse/BET-3202
         */
        const firstWaypoint = rowData.waypoints[0] || {}
        const sender = firstWaypoint.contact_name || rowData.nickname || '-'
        const senderPhoneNumber =
          firstWaypoint.contact_phone_number || rowData.phone_number || ''

        return (
          <CustomizedCell>
            <Body noMargin>{sender}</Body>
            <SubText noMargin>{senderPhoneNumber}</SubText>
          </CustomizedCell>
        )
      },
    },
    {
      width: '120px',
      headerLabel: t('common__recipient_info'),
      headerComponent: ({ label }) => (
        <CustomizedHeader>{label}</CustomizedHeader>
      ),
      cellComponent: ({ rowData }) => {
        const lastWaypoint =
          rowData.waypoints[rowData.waypoints.length - 1] || {}
        const recipient = lastWaypoint.contact_name || '-'
        const recipientPhoneNumber = lastWaypoint.contact_phone_number
          ? `(${lastWaypoint.contact_phone_number})`
          : ''
        return (
          <CustomizedCell>
            <Body noMargin>{recipient}</Body>
            <SubText noMargin>{recipientPhoneNumber}</SubText>
          </CustomizedCell>
        )
      },
    },
    {
      key: 'driver_info',
      width: '125px',
      headerLabel: t('common__driver_info'),
      headerComponent: ({ label }) => (
        <CustomizedHeader>{label}</CustomizedHeader>
      ),
      cellComponent: ({ rowData = {} }) => {
        const driverInformation = rowData.order || rowData.linkedOrder || {}
        const {
          driver_nickname,
          driver_phone_number,
          driver_vehicle_type,
          driver_license_plate,
        } = driverInformation

        return (
          <FlexContainerColumn>
            {driver_nickname ? (
              <>
                <Body noMargin>
                  {driver_nickname || '-'}
                  <br />
                  {driver_phone_number || '-'}
                </Body>
                {driver_vehicle_type === 'van' && <SubText></SubText>}
                <SubText>
                  {driver_vehicle_type && driver_license_plate
                    ? `${(getCountry() === 'HK' &&
                      premiumBlackVanFeatureValue !== 'control' &&
                      driver_vehicle_type === 'van'
                        ? `${t('common__van')}/${t('premium_van')}`
                        : t(VEHICLE_TRANSLATIONS[driver_vehicle_type])) ||
                        '-'} · ${driver_license_plate || '-'}`
                    : '-'}
                </SubText>
              </>
            ) : (
              <Body noMargin>-</Body>
            )}
          </FlexContainerColumn>
        )
      },
    },
    {
      key: 'final_price',
      width: '86px',
      headerLabel: t('common__price'),
      headerComponent: ({ label }) => (
        <CustomizedHeader>{label}</CustomizedHeader>
      ),
      cellComponent: ({ value, rowData: { status } }) => {
        const price = formatCurrency({ amount: value })
        return (
          <CustomizedCell>
            {status !== 'cancelled' ? price : formatCurrency({ amount: 0 })}
            {status === 'cancelled' && (
              <SubText style={{ textDecoration: 'line-through' }}>
                {price}
              </SubText>
            )}
          </CustomizedCell>
        )
      },
    },
    {
      key: 'id',
      width: '144px',
      headerLabel: t('merchant_order_note'),
      headerComponent: ({ label }) => (
        <CustomizedHeader>{label}</CustomizedHeader>
      ),
      cellComponent: ({ rowData }) => (
        <CustomizedTextCell>
          {rowData.order_request_extra?.internal_notes}
        </CustomizedTextCell>
      ),
    },
  ]

  return isSubUser
    ? columns.filter(column => column.key !== 'placed_by')
    : columns
}

export { useColumns }
