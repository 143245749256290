import React from 'react'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import {
  Wrapper,
  StyledHeading,
  RatingWrapper,
  StyledBody,
  StyledStarSelected,
  StyledStarUnselected,
} from './order-heading.component.styles'
import { VEHICLE_TRANSLATIONS } from '../../../service/vehicle/vehicle'
import { useFeatureFlagValue } from 'ggx-global/hooks/use-feature-flag'

const NUMBERSTARS = [1, 2, 3, 4, 5]

const OrderHeading = ({ orderId, vehicle, rating, breakdownResponse = {} }) => {
  const [t] = useTranslation()

  const premiumBlackVanFeatureValue = useFeatureFlagValue(
    'premium_black_van_ab_testing'
  )

  return (
    <Wrapper isRated={!!rating}>
      <StyledHeading>
        {t('text__order_id_vehicle', {
          number: `#${orderId}`,
          vehicle: t(
            premiumBlackVanFeatureValue === 'on' &&
              vehicle === 'van' &&
              breakdownResponse?.breakdown?.new_car?.value === 20
              ? VEHICLE_TRANSLATIONS.premiumVan
              : VEHICLE_TRANSLATIONS[vehicle]
          ),
        })}
      </StyledHeading>
      {rating && (
        <RatingWrapper>
          <StyledBody noMargin>{t('text__order_rating')}</StyledBody>
          {NUMBERSTARS.map(index => {
            if (index <= rating) {
              return <StyledStarSelected />
            }

            return <StyledStarUnselected />
          })}
        </RatingWrapper>
      )}
    </Wrapper>
  )
}

OrderHeading.propTypes = {
  orderId: PropTypes.string,
  vehicle: PropTypes.string,
  rating: PropTypes.number,
}

export { OrderHeading, NUMBERSTARS }
