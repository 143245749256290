import { Api } from '../../legacy/utils/api'
import Constants from '../../legacy/constants/action'

const _api_b2b = new Api(Api.BASE_URL.B2B)
const _api = new Api(Api.BASE_URL.API_URL)

const adminService = {
  getOrganizations: async (queryString = '') => {
    const method = 'get'
    const endpoint = '/organizations'
    const params = { name_contains: queryString }

    try {
      const result = await _api_b2b.request(
        method,
        endpoint,
        null,
        null,
        null,
        null,
        params
      )
      return result.data
    } catch (error) {
      throw error
    }
  },
  switchOrganizationAndBranch: async ({ orgId, branchId }) => {
    const method = 'post'
    const endpoint = '/users/switch_organization'
    const body = { organization_id: orgId, branch_id: branchId }

    try {
      const result = await _api_b2b.request(method, endpoint, null, body)
      return result.data
    } catch (error) {
      throw error
    }
  },
}

const couponService = {
  getCoupons: async ({ orderPrice, voucherCode, serviceType, vehicleType }) => {
    const method = 'get'
    const endpoint = '/user_vouchers'
    const params = {
      order_price: orderPrice?.toString(),
      voucher_code: voucherCode,
      service_type: serviceType,
      vehicle_type: vehicleType,
    }
    try {
      const result = await _api.request(
        method,
        endpoint,
        null,
        null,
        null,
        null,
        params
      )
      return result.data
    } catch (error) {
      throw error
    }
  },
  redeem: async (code, { orderPrice, serviceType, vehicleType } = {}) => {
    const method = 'post'
    const endpoint = '/user_vouchers/redeem'

    try {
      const result = await _api.request(
        method,
        endpoint,
        null,
        {
          voucher_code: code,
          order_price: orderPrice?.toString(),
          service_type: serviceType,
          vehicle_type: vehicleType,
        },
        null,
        null,
        null
      )
      return result.data
    } catch (error) {
      throw error
    }
  },
}

const userService = {
  updateConsentTimestamp: () => {
    const method = 'post'
    const endpoint = '/user/update_tnc_agree_timestamp'

    return _api.request(
        method,
        endpoint,
        null,
        null,
        null,
        null,
        null
      )
  },
}

export { _api_b2b, adminService, couponService, userService }
